import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/happyUserSlider.css";
import circle from "../assets/images/circle.png";
import fingerPrint from "../assets/images/fingerprint-03.png";
import deviceInteli from "../assets/images/deviceInteli.svg";
import browserInteli from "../assets/images/browserInteli.svg";
import glob from "../assets/images/global.svg";
import globalSearch from "../assets/images/global-search.svg";

export const SmartProtectionSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables auto scroll
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991, // Tablet and smaller
        settings: {
          slidesToShow: 2, // Show 2 slides on tablet
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile and smaller
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
              <img className="img1" src={fingerPrint} alt="" />
              <h2>Browser Intelligence</h2>
              <p>
                Unlock actionable insights into user behavior and browser data
                for enhanced security and audience understanding
              </p>
              <img className="img2" src={fingerPrint} alt="" />
            </div>
          </div>
        </div>

        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
              <img className="img1" src={deviceInteli} alt="" />
              <h2>Device Intelligence</h2>
              <p>
                Identify and secure devices accessing your platform with
                real-time data, fingerprinting, and anomaly detection.
              </p>
              <img className="img2" src={deviceInteli} alt="" />
            </div>
          </div>
        </div>
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
              <img className="img1" src={glob} alt="" />
              <h2>IP Intelligence</h2>
              <p>
                Monitor and analyze IP addresses with geolocation tracking,
                reputation scoring, and proxy/VPN detection for secure
                operations.
              </p>
              <img className="img2" src={glob} alt="" />
            </div>
          </div>
        </div>
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
              <img className="img1" src={globalSearch} alt="" />
              <h2>Fraud Risk Management</h2>
              <p>
                Protect your business with real-time fraud detection, machine
                learning-based risk analysis, and customizable prevention tools.
              </p>
              <img className="img2" src={globalSearch} alt="" />
            </div>
          </div>
        </div>
        <div className="sliderCard1">
          <div className="section">
            <div className="content" style={{ padding: "40px" }}>
              <img className="img1" src={globalSearch} alt="" />
              <h2>Upi Intent Flow</h2>
              <p>
                Payment Gateway option active!
              </p>
              <img className="img2" src={globalSearch} alt="" />
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};
