import "./App.css";
import "./styles/variable.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/navbar";
import Footer from "./components/footer";
import { Home } from "./pages/home";
import { NewPage } from "./pages/description";
import { IpIntelligence } from "./pages/ipIntelligence";
import { BrowserIntelligence } from "./pages/browserIntelligence";
import { FraudRiskManagement } from "./pages/FraudRiskManagement";
import { DeviceIntelligence } from "./pages/deviceIntelligence";
import { DeviceAndBrowserIntelligence } from "./pages/deviceAndBrowserIntelligence";
import { About } from "./pages/about";
import { ComingSoonPage } from "./pages/comingSoon";
import { ContactUs } from "./pages/contactUs";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { TermAndCondition } from "./pages/termAndCondition";
import { ScrollToTop } from "./pages/scrollTop";
import { UpiIntentFlow } from "./pages/upiIntentFlow";
import { Contact} from "./pages/contact";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ip-intelligence" element={<IpIntelligence />} />
          <Route
            path="/browser-intelligence)"
            element={<BrowserIntelligence />}
          />
          <Route
            path="/fraud-risk-management"
            element={<FraudRiskManagement />}
          />
          <Route
            path="/device-intelligence"
            element={<DeviceAndBrowserIntelligence />}
          />
          <Route path="/about-us" element={<About />} />
          <Route path="/documentation" element={<ComingSoonPage />} />
          <Route path="/blogs" element={<ComingSoonPage />} />
          <Route path="/events" element={<ComingSoonPage />} />
          <Route path="/whitepapers" element={<ComingSoonPage />} />
          <Route path="/partner" element={<ComingSoonPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termAndCondition" element={<TermAndCondition />} />
          <Route path="/upiIntentFlow" element={<UpiIntentFlow />} />
          <Route path="/contact" element={<Contact />}/>
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
