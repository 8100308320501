import TopHeader from "./topHeader";
import Secure from "../pages/secure";
import Management from "./management";
import PaymentStack from "./paymentStack";
import { GetInTouch } from "./getInTouch";
import { WhoTrust } from "./whotrust";
import { WhyFtac } from "./whyFstac";
import { HappyUser } from "./happyUser";


export const Home = () => {
  return (
    <div>
      <TopHeader />
      {/* <WhoTrust /> */}
      <Secure />
      
      {/* <Management /> */}
      {/* <PaymentStack /> */}

      <WhyFtac />
      <HappyUser />
      <GetInTouch
        heading="Let’s get in touch!"
        subheading="We’ll make your business safer. Lets discuss how"
        buttonText=" Contact Us"
      />
    </div>
  );
};
