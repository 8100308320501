import { HappyUserStory } from "./happyUserSlider";
import "../styles/happyuser.css";

export const HappyUser = () => {
  return (
    <div className="container happyUser">
      <div className="">
        <div className="row mb-5">
          <div className="col-lg-12">
            <div className="happyUserSubHeading text-center">What Our</div>
            <div>
              <h2 className="text-center">Happy Users Say</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <HappyUserStory />
          </div>
        </div>
      </div>
    </div>
  );
};
