import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { SmartProtectionSlider } from './smartProtectionSlider';
import device from "../assets/images/device_icon.png";
import ip from "../assets/images/ipIntelligence.svg";
import payment from "../assets/images/payment.png";
import fraud from "../assets/images/fraudIntelligence.svg";
import device_ from "../assets/images/deviceIntelligence.svg";
import fingerPrint from "../assets/images/fingerprint-03.png";
import deviceInteli from "../assets/images/deviceInteli.svg";
import browserInteli from "../assets/images/browserInteli.svg";
import glob from "../assets/images/global.svg";
import globalSearch from "../assets/images/global-search.svg";
import "../styles/secure.css";

export const UpiIntentFlow = () => {
    const [activeSection, setActiveSection]=useState(0);
    const handleMouseLeave = () => {
        setActiveSection(0);
      };
      return (
        <>
          <div className="frameParent container-fluid p-5">
            <div className="frameGroup">
              <div className="securePaymentsSmarterProteParent">
                <div className="securePaymentsSmarterContainer">
                  <span className="secure">Secure</span>
                  <span>{` `}</span>
                  <span className="payments">Payments</span>
                  <span>{`, `}</span>
                  <span className="secure">Smarter</span>
                  <span>{` `}</span>
                  <span className="payments">Protection</span>
                </div>
    
                <div className="fromRealTimeFraud">
                  From real-time fraud detection and advanced data intelligence to
                  multi-layered security and adaptive risk scoring, Fstac empowers
                  your business to detect, prevent, and mitigate fraud, ensuring
                  robust protection and seamless operations
                </div>
              </div>
              <div className="sliderSecure">
                <SmartProtectionSlider />
              </div>
              <div className="intelligence-container">
                <div
                  className={`section ${activeSection === 0 ? "active" : ""}`}
                  onMouseEnter={() => setActiveSection(0)}
                  onMouseLeave={handleMouseLeave}
                >
                  {activeSection !== 0 && (
                    <div className="box_content">
                      <img src={browserInteli} alt="" />
                    </div>
                  )}
                  <Link to={'/device-intelligence'} className="CartLink">
                  <div className="content">
                    <img className="img1" src={fingerPrint} alt="" />
                    <h2>Browser Intelligence</h2>
                    <p>
                      Unlock actionable insights into user behavior and browser data
                      for enhanced security and audience understanding
                    </p>
                    <img className="img2" src={fingerPrint} alt="" />
                  </div>
                  </Link>
                </div>
                <div
                  className={`section ${activeSection === 1 ? "active" : ""}`}
                  onMouseEnter={() => setActiveSection(1)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="box_content">
                    <img src={device_} alt="" />
                  </div>
                  <Link to={'/device-intelligence'} className="CartLink">
                  <div className="content">
                    <img className="img1" src={deviceInteli} alt="" />
                    <h2>Device Intelligence</h2>
                    <p>
                      Identify and secure devices accessing your platform with
                      real-time data, fingerprinting, and anomaly detection
                    </p>
                    <img className="img2" src={deviceInteli} alt="" />
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    
