// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.happyUserSubHeading {
  font-family: "Gilroy-Medium";
  font-size: 38px;
}
.happyUser {
  padding: 7%;
  border-radius: 12px;
  background: rgba(250, 159, 66, 0.1);
  margin-top: 5%;
  margin-bottom: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/happyuser.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,eAAe;AACjB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,mCAAmC;EACnC,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".happyUserSubHeading {\n  font-family: \"Gilroy-Medium\";\n  font-size: 38px;\n}\n.happyUser {\n  padding: 7%;\n  border-radius: 12px;\n  background: rgba(250, 159, 66, 0.1);\n  margin-top: 5%;\n  margin-bottom: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
