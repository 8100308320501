import "../styles/contact.css";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ThankYouModal } from "./thankyou";
import logo from "../assets/images/logo.svg";

// Define the shape of the form values
interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  interest: string;
  termsAccepted: boolean;
};

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters.")
    .max(50, "First name can't exceed 50 characters.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters.")
    .max(50, "Last name can't exceed 50 characters.")
    .required("Last name is required."),
  email: Yup.string()
    .email("Invalid email format.")
    .required("Email is required."),
  contactNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Contact number must be exactly 10 digits.")
    .required("Contact number is required."),
  interest: Yup.string().required("Please select what you are interested in."),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions."
  ),
});

export const Contact: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    interest: "",
    termsAccepted: false,
  };

  const handleSubmit = async (values: ContactFormValues) => {
    const payload = { ...values };

    try {
      const response = await fetch("https://uat0.fstac.tech/id/website/serviceleads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setModalOpen(true);
      } else {
        console.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const closeModal = (resetForm: () => void) => {
    setModalOpen(false);
    resetForm(); // Reset the form when the modal closes
  };

  return (
    <div id="contactUs">
      <div className="container">
        <div className="contactUs">
          <div className="row justify-content-center align-items-center pb-5">
            <div className="col-lg-6">
              <div className="contactHeading">
                <h5 className="text-center">Contact Us</h5>
                <h2 className="text-center">Get in Touch</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9">
              <div className="contactUsForm">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ resetForm, isValid, dirty }) => (
                    <Form className="p-5">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <Field
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          placeholder="Enter your first name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <Field
                          type="text"
                          id="lastName"
                          name="lastName"
                          className="form-control"
                          placeholder="Enter your last name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="contactNumber">Contact Number</label>
                        <Field
                          type="text"
                          id="contactNumber"
                          name="contactNumber"
                          className="form-control"
                          placeholder="Enter your contact number"
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="interest">What are you interested in?</label>
                        <Field
                          as="select"
                          id="interest"
                          name="interest"
                          className="form-control"
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          <option value="KYC Services">KYC Services</option>
                          <option value="Payment Gateway Services">
                            Payment Gateway Services
                          </option>
                          <option value="Identity Verification Services">
                            Identity Verification Services
                          </option>
                          <option value="Fraud Prevention Services">
                            Fraud Prevention Services
                          </option>
                        </Field>
                        <ErrorMessage
                          name="interest"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <Field
                            type="checkbox"
                            name="termsAccepted"
                            className="mr-2"
                          />
                          By submitting this form, you agree to our{" "}
                          <a href="/termAndCondition" className="tnclink">
                            Terms & Condition
                          </a>
                          .
                        </label>
                        {/* <ErrorMessage
                          name="termsAccepted"
                          component="div"
                          className="error"
                        /> */}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!(isValid && dirty)}
                      >
                        Submit
                      </button>
                      <ThankYouModal
                        isOpen={isModalOpen}
                        onClose={() => closeModal(resetForm)}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="contactInfo mt-5">
                <h4>Contact Us</h4>
                <img src={logo} alt="Fstac"/> 
                <p>
                  <strong>Address:</strong> XJHV+MFQ, Shri Krishna Temple Rd,
                  Indira Nagar 1st Stage, Stage 1, Indiranagar, Bengaluru,
                  Karnataka 560038
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <a href="mailto:sales@fstac.tech">sales@fstac.tech</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
