import React, { useState, useEffect } from "react";
import "../styles/header.css";
import logo from "../assets/images/logo.svg";
import downArrow from "../assets/images/newArrow-down.svg";
import arrowright from "../assets/images/arrow-right-blue.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import menu from "../assets/images/hamburge.svg";

const NavBarComponent = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const changeRoute = () => {
    navigate("/");
  };

  const toggleButton = () => {
    setIsMobileView(!isMobileView);
  };
  useEffect(() => {
    setActiveIndex(null);
    setIsMobileView(false);
  }, [location.pathname]);

  return (
    <div className="navbarHeader">
      <div className="container p-1">
        {/* destopView  */}
        <div className="row align-items-center justify-content-between desktopView">
          <div className="col-lg-1 d-flex align-items-center">
            <div className="image_section" onClick={changeRoute}>
              <img className="clipPathGroup" alt="" src={logo} />
            </div>
          </div>

          <div className="menuItemParent col-lg-6 d-flex justify-content-around">
            <div
              className={`menuItem d-flex ${activeIndex === 0 ? "active" : ""}`}
            >
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Products</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <div className="dropdown">
                  <div className="d-flex">
                    <div className="boxDropdown">
                      <div className="heading">FRAUD WATCH</div>
                      <div>
                        <ul>
                          <li
                            onClick={() => {
                              navigate("/device-intelligence");
                            }}
                          >
                            Device & Browser Intelligence
                          </li>

                          <li
                            onClick={() => {
                              navigate("/ip-intelligence");
                            }}
                          >
                            IP Intelligence
                          </li>
                          <li
                            onClick={() => {
                              navigate("/fraud-risk-management");
                            }}
                          >
                            Fraud Risk Management
                          </li>
                        </ul>
                      </div>
                    </div>
                    {<div className="boxDropdown">
                      <div className="heading">PAYMENT</div>
                      <div>
                        <ul>
                          <li>UPI Intent Flow</li>
                          <li>UPI Collect Flow</li>
                          <li>Payouts</li>
                        </ul>
                      </div>
                    </div> }
                  </div>
                </div>
              </div>
            </div>

            <div className={`menuItem d-flex `}>
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Developers</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/documentation");
                    }}
                  >
                    Documentation
                  </li>
                </ul>
              </div>
            </div>
            <div className={`menuItem d-flex `}>
              <div className="d-flex align-items-center menuItemWrapper">
                <div
                  className=""
                  onClick={() => {
                    navigate("/partner");
                  }}
                >
                  <div className="menuItem1">Partner</div>
                </div>
              </div>
            </div>
            <div className={`menuItem d-flex `}>
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Resources</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/blogs");
                    }}
                  >
                    Blog
                  </li>
                  <li
                    onClick={() => {
                      navigate("/events");
                    }}
                  >
                    Events
                  </li>
                  <li
                    onClick={() => {
                      navigate("/whitepapers");
                    }}
                  >
                    Whitepapers
                  </li>
                </ul>
              </div>
            </div>
            <div className={`menuItem `}>
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Company</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  >
                    About Us
                  </li>
                  <li
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Contact Us
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="primaryButton col-lg-2">
            <button
              type="button"
              className="btn btn-primary contact-us d-flex"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Request Demo
              <span className="arrowImage">
                <img src={arrowright} alt="" />
              </span>
            </button>
          </div>
        </div>

        {/* Mobile view  */}
        <div className="row align-items-center justify-content-between mobileView p-3">
          <div className=" d-flex align-items-center justify-content-between">
            <div className="image_section" onClick={changeRoute}>
              <img className="clipPathGroup" alt="" src={logo} />
            </div>
            <div className="image_section" onClick={toggleButton}>
              <img className={"menu"} alt="" src={menu} />
            </div>
          </div>

          <div
            className={
              " menuItemParentMobile " +
              (isMobileView ? " showMobile" : " hideMobile")
            }
          >
            <div
              className={`menuItem menuItemMobile  ${
                activeIndex === 0 ? "active" : ""
              }`}
              onClick={() => {
                toggleDropdown(0);
              }}
            >
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Products</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <div className="dropdown">
                  <div className="d-flex">
                    <div className="boxDropdown">
                      <div className="heading">FRAUD WATCH</div>
                      <div>
                        <ul>
                          <li
                            onClick={() => {
                              navigate("/device-intelligence");
                            }}
                          >
                            Device & Browser Intelligence
                          </li>

                          <li
                            onClick={() => {
                              navigate("/ip-intelligence");
                            }}
                          >
                            IP Intelligence
                          </li>
                          <li
                            onClick={() => {
                              navigate("/fraud-risk-management");
                            }}
                          >
                            Fraud Risk Management
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="boxDropdown">
                      <div className="heading">PAYMENT</div>
                      <div>
                        <ul>
                          <li>UPI Intent Flow</li>
                          <li>UPI Collect Flow</li>
                          <li>Payouts</li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`menuItem d-flex ${activeIndex === 1 ? "active" : ""}`}
              onClick={() => {
                toggleDropdown(1);
              }}
            >
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Developers</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/documents");
                    }}
                  >
                    Documentation
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`menuItem d-flex ${activeIndex === 2 ? "active" : ""}`}
              onClick={() => {
                toggleDropdown(2);
              }}
            >
              <div className="d-flex align-items-center menuItemWrapper">
                <div
                  className=""
                  onClick={() => {
                    navigate("/partner");
                  }}
                >
                  <div className="menuItem1">Partner</div>
                </div>
              </div>
            </div>
            <div
              className={`menuItem d-flex ${activeIndex === 3 ? "active" : ""}`}
              onClick={() => {
                toggleDropdown(3);
              }}
            >
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Resources</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/blogs");
                    }}
                  >
                    Blog
                  </li>
                  <li
                    onClick={() => {
                      navigate("/events");
                    }}
                  >
                    Events
                  </li>
                  <li
                    onClick={() => {
                      navigate("/whitepapers");
                    }}
                  >
                    Whitepapers
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`menuItem d-flex ${activeIndex === 4 ? "active" : ""}`}
              onClick={() => {
                toggleDropdown(4);
              }}
            >
              <div className="d-flex align-items-center menuItemWrapper">
                <div className="">
                  <div className="menuItem1">Company</div>
                </div>
                <img
                  className="menuItemArrowIcon"
                  style={{ height: "7px", paddingLeft: "2px" }}
                  alt=""
                  src={downArrow}
                />
              </div>
              <div className="itemList1">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  >
                    About Us
                  </li>
                  <li
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Contact Us
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="primaryButton col-lg-2">
              <button
                type="button"
                className="btn btn-primary contact-us d-flex"
              >
                Request Demo
                <span className="arrowImage">
                  <img src={arrowright} alt="" />
                </span>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarComponent;
