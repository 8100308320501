import { useState } from "react";
import "../styles/secure.css";
import device from "../assets/images/device_icon.png";
import ip from "../assets/images/ipIntelligence.svg";
import payment from "../assets/images/payment.png";
import fraud from "../assets/images/fraudIntelligence.svg";
import device_ from "../assets/images/deviceIntelligence.svg";
import fingerPrint from "../assets/images/fingerprint-03.png";
import deviceInteli from "../assets/images/deviceInteli.svg";
import browserInteli from "../assets/images/browserInteli.svg";
import glob from "../assets/images/global.svg";
import globalSearch from "../assets/images/global-search.svg";
import { SmartProtectionSlider } from "./smartProtectionSlider";
import { Link } from "react-router-dom";

const Secure = () => {
  const [activeSection, setActiveSection] = useState(0);
  const handleMouseLeave = () => {
    setActiveSection(0);
  };
  return (
    <>
      <div className="frameParent container-fluid p-5">
        <div className="frameGroup">
          <div className="securePaymentsSmarterProteParent">
            <div className="securePaymentsSmarterContainer">
              <span className="secure">Secure</span>
              <span>{` `}</span>
              <span className="payments">Payments</span>
              <span>{`, `}</span>
              <span className="secure">Smarter</span>
              <span>{` `}</span>
              <span className="payments">Protection</span>
            </div>

            <div className="fromRealTimeFraud">
              From real-time fraud detection and advanced data intelligence to
              multi-layered security and adaptive risk scoring, Fstac empowers
              your business to detect, prevent, and mitigate fraud, ensuring
              robust protection and seamless operations
            </div>
          </div>
          <div className="sliderSecure">
            <SmartProtectionSlider />
          </div>
          <div className="intelligence-container">
            <div
              className={`section ${activeSection === 0 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(0)}
              onMouseLeave={handleMouseLeave}
            >
              {activeSection !== 0 && (
                <div className="box_content">
                  <img src={browserInteli} alt="" />
                </div>
              )}
              <Link to={'/device-intelligence'} className="CartLink">
              <div className="content">
                <img className="img1" src={fingerPrint} alt="" />
                <h2>Browser Intelligence</h2>
                <p>
                  Unlock actionable insights into user behavior and browser data
                  for enhanced security and audience understanding
                </p>
                <img className="img2" src={fingerPrint} alt="" />
              </div>
              </Link>
            </div>
            <div
              className={`section ${activeSection === 1 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(1)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="box_content">
                <img src={device_} alt="" />
              </div>
              <Link to={'/device-intelligence'} className="CartLink">
              <div className="content">
                <img className="img1" src={deviceInteli} alt="" />
                <h2>Device Intelligence</h2>
                <p>
                  Identify and secure devices accessing your platform with
                  real-time data, fingerprinting, and anomaly detection
                </p>
                <img className="img2" src={deviceInteli} alt="" />
              </div>
              </Link>
            </div>
            <div
              className={`section ${activeSection === 2 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(2)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="box_content">
                <img src={ip} alt="" />
              </div>
              <Link to={'/ip-intelligence'} className="CartLink">
              <div className="content">
                <img className="img1" src={glob} alt="" />

                <h2>IP Intelligence</h2>
                <p>
                  Monitor and analyze IP addresses with geolocation tracking,
                  reputation scoring, and proxy/VPN detection for secure
                  operations
                </p>
                <img className="img2" src={glob} alt="" />
              </div>
              </Link>
            </div>
            
            <div
              className={`section ${activeSection === 3 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(3)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="box_content">
                <img src={fraud} alt="" />
              </div>
              <Link to={'/fraud-risk-management'} className="CartLink">
              <div className="content">
                <img className="img1" src={globalSearch} alt="" />

                <h2>Fraud Risk Management</h2>
                <p>
                  Protect your business with real-time fraud detection, machine
                  learning-based risk analysis, and customizable prevention
                  tools
                </p>
                <img className="img2" src={globalSearch} alt="" />
              </div>
              </Link>
            </div>
            {/* <div
              className={`section ${activeSection === 4 ? "active" : ""}`}
              onMouseEnter={() => setActiveSection(4)}
              onMouseLeave={handleMouseLeave}
            > */}
              {/* <div className="box_content"> */}
                {/* <img src={ip} alt="" /> */}
              {/* </div>
              <Link to={'/upiIntentFlow'} className="CartLink">
              <div className="content">
                <img className="img1" src={glob} alt="" /> */}

                {/* <h2>Upi Intent Flow</h2>
                <p>
                  Payment gateway is active!
                </p> */}
                {/* <img className="img2" src={glob} alt="" />
              </div>
              </Link> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Secure;
