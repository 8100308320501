import "../styles/about.css";
import natarajan from "../assets/images/Natarajan.svg";
import utkarsh from "../assets/images/utkarshImage.svg";
import natrajanImage from "../assets/images/Nataranjanimage.png";
import rahulImage from "../assets/images/rahulImage.svg";


export const About = () => {
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-lg-12">
            <div className="howItBox d-flex justify-content-center">
              <div className="howItStarted">
                <div className="headingHowItStarted text-center">
                  How it Started
                </div>
                <div>
                  <p className="text-center">
                    Fstac was founded with a mission to revolutionize the
                    fintech space by providing cutting-edge solutions for fraud
                    prevention, behavioral intelligence, and payment security.
                  </p>
                  <p className="text-center">
                    Fstac emerged from Utkarsh's & Natarajan's vision to create a
                    platform that integrates the best of fraud monitoring, risk
                    management, and seamless payment flows, enabling businesses
                    to operate securely in a digital-first world. 
                  </p>
                </div>
              </div>
              <div className="natarajan">
                <div className="natrajanImage">
                  <img src={natarajan} className="companyPeople" alt="" />
                </div>
                <div className="name NatarajanName">Natarajan</div>
              </div>
              <div className="Utkarsh">
                <div className="UtkarshImage">
                  <img src={utkarsh} className="companyPeople" alt="" />
                </div>
                <div className="name utkarshName">Utkarsh</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "50px" }}>
        <div className="row justify-content-center align-itmes-center p-5">
          <div className="col-lg-6">
            <div className="Info">
              <div className="infoHeading">
                <h3>Utkarsh Srivastava</h3>
              </div>
              <div className="infoCeo">CEO</div>
              <div className="infoDescription">
                <p>
                  Utkarsh has an extensive experience in Fintech across
                  payments, banking, and security, having worked at notable
                  institutions such as RBL and Decentro. He co-founded Zeppery,
                  which successfully raised USD 77K in 2015.
                </p>
                <p>
                  As an angel investor and advisor, he actively contributes his
                  industry expertise to several FinTech startups. He enjoys
                  sharing his knowledge and insights to support the growth and
                  innovation of the fintech ecosystem.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-end align-itmes-center">
            <div className="utkarshImage">
              <img src={utkarsh} alt="" className="img-fluid " />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-itmes-center p-5">
          <div className="col-lg-6">
            <div className="Info">
              <div className="infoHeading">
                <h3>Natarajan Ranganathan</h3>
              </div>
              <div className="infoCeo">Independent Director</div>
              <div className="infoDescription">
                <p>
                Natarajan has over 30 years of expertise in financial management, organizational transformation, 
                and strategic advisory across VC/PE like UC RNT Fund and Helion Venture, 
                Natarajan brings unparalleled experience in business restructuring and growth.
                </p>
                <p>
                His leadership in guiding organizations through due diligence, 
                and investment strategies will be instrumental in accelerating Fstac’s go-to-market strategy
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-end align-itmes-center">
            <div className="utkarshImage">
              <img src={natrajanImage} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-itmes-center p-5">
          <div className="col-lg-6 col-sm-6">
            <div className="Info">
              <div className="infoHeading">
                <h3>Rahul Pandey</h3>
              </div>
              <div className="infoCeo">Co-founder</div>
              <div className="infoDescription">
                <p>
                  With over eighteen years of experience, Rahul has co-founded
                  several innovative ventures.
                </p>
                <p>
                  At Fstac, he leads a team building integrational services for
                  companies, while also overseeing custom software development
                  at Techindo Systems. He also co-founded Let It Wag, a social
                  network for pet owners and animal welfare causes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 d-flex justify-content-end align-itmes-center">
            <div className="utkarshImage">
              <img src={rahulImage} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
